<template>
  <b-card>
    <b-card-header>
      <div class="text-primary h2 col-12">
        <b-row>
          <!-- Right Col: Table -->
          <b-col
            cols="9"
            xl="9">
            <span class="font-weight-bold"
              >{{ $t(elementName + '.section.view') }}
            </span>
          </b-col>
        </b-row>
        <br />
      </div>
    </b-card-header>
    <b-card-body>
      <validation-observer
        ref="elementForm"
        #default="{ invalid }">
        <b-form @submit.prevent="save">
          <b-row>
            <b-col cols="4">
              <b-form-group label-for="made_by_avenir">
                <span class="font-weight-bold">{{
                  $t(elementName + '.object.made_by_avenir')
                }}</span
                ><span class="text-danger">*</span>
                <validation-provider
                  #default="{ errors }"
                  name="made_by_avenir"
                  rules="required">
                  <v-select
                    v-model="elementData.made_by_avenir"
                    :options="madeByAvenirOptions"
                    label="text"
                    value="value"
                    :reduce="(option) => option.value"
                    @input="updateLabs" />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col cols="4" />
            <b-col cols="2">
              <span class="font-weight-bold">{{
                $t(elementName + '.object.auth_number')
              }}</span>
            </b-col>
            <b-col cols="2">
              <b-form-input
                id="auth_number"
                v-model="elementData.auth_number"
                maxlength="20"
                disabled />
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="4">
              <b-form-group label-for="consult_type">
                <span class="font-weight-bold">{{
                  $t(elementName + '.object.consult_type')
                }}</span>

                <template v-if="elementData.order">
                  <v-select
                    v-model="elementData.next_consult_id"
                    :options="followTypeList"
                    label="value"
                    value="id"
                    :reduce="(option) => option.id"
                    disabled />
                </template>
                <template v-else>
                  <validation-provider
                    #default="{ errors }"
                    :name="$t(elementName + '.object.name')"
                    rules="required">
                    <v-select
                      v-model="elementData.next_consult_id"
                      :options="followTypeList"
                      label="value"
                      value="id"
                      :reduce="(option) => option.id" />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </template>
              </b-form-group>
            </b-col>
            <b-col cols="4">
              <b-form-group label-for="name">
                <span class="font-weight-bold">{{
                  $t(elementName + '.object.name')
                }}</span
                ><span class="text-danger">*</span>
                <validation-provider
                  #default="{ errors }"
                  :name="$t(elementName + '.object.name')"
                  rules="required">
                  <v-select
                    v-model="elementData.cups"
                    :options="clinicalLaboratoryServicesList"
                    label="text"
                    value="cups"
                    :reduce="(option) => option.cups"
                    @input="onCUPSChange" />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col cols="4">
              <b-form-group label-for="date">
                <date-picker
                  ref="first_dosage_id"
                  :element-required="true"
                  element-field="date"
                  :element-label="$t(elementName + '.object.date')"
                  :root-element-data="elementData" />
              </b-form-group>
            </b-col>
          </b-row>

          <b-row>
            <b-col cols="4">
              <b-form-group label-for="result">
                <span class="font-weight-bold">{{
                  $t(elementName + '.object.result')
                }}</span
                ><span class="text-danger">*</span>
                <validation-provider
                  #default="{ errors }"
                  name="result"
                  rules="required">
                  <v-select
                    v-model="elementData.result_id"
                    :options="testResults"
                    label="value"
                    value="id"
                    :reduce="(option) => option.id" />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col cols="4">
              <b-form-group label-for="value">
                <span class="font-weight-bold">{{
                  $t(elementName + '.object.value')
                }}</span
                ><span class="text-danger">*</span>
                <validation-provider
                  #default="{ errors }"
                  :name="$t(elementName + '.object.value')"
                  rules="required">
                  <b-form-input
                    id="value"
                    v-model="elementData.value"
                    type="number"
                    step="0.0001"
                    min="0.0000"
                    max="100000.0000" />

                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col cols="4">
              <b-form-group label-for="unit">
                <span class="font-weight-bold">{{
                  $t(elementName + '.object.unit')
                }}</span
                ><span class="text-danger">*</span>
                <validation-provider
                  #default="{ errors }"
                  name="unit"
                  rules="required">
                  <v-select
                    v-model="elementData.unit_id"
                    :options="unitsOfMeasure"
                    label="key"
                    value="id"
                    :reduce="(option) => option.id" />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>

          <b-row>
            <b-col
              cols="4"
              v-if="elementData.audit_action == 'MIGRATION'">
              <b-form-group label-for="provider">
                <span class="font-weight-bold">{{
                  $t(elementName + '.object.provider')
                }}</span
                ><span class="text-danger">*</span>
                <validation-provider
                  #default="{ errors }"
                  name="provider"
                  rules="required">
                  <v-select
                    v-model="elementData.provider_id"
                    :options="labs"
                    label="name"
                    value="id"
                    :reduce="(option) => option.id"
                    @search="searchLab" />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col
              cols="4"
              v-else>
              <b-form-group label-for="provider">
                <span class="font-weight-bold">{{
                  $t(elementName + '.object.provider')
                }}</span
                ><span class="text-danger">*</span>
                <validation-provider
                  #default="{ errors }"
                  name="provider"
                  rules="required">
                  <v-select
                    v-model="elementData.provider_id"
                    :options="labs"
                    label="name"
                    value="id"
                    :reduce="(option) => option.id"
                    @search="searchLab" />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col cols="8">
              <b-form-group label-for="report">
                <span class="font-weight-bold">{{
                  $t(elementName + '.object.report')
                }}</span>
                <validation-provider
                  #default="{ errors }"
                  name="report"
                  rules="">
                  <b-row>
                    <b-col cols="9">
                      <!-- ? VIEW -->
                      <b-tooltip
                        target="view-report-button"
                        :title="$t(elementName + '.actions.view_report')"
                        triggers="hover"
                        :noninteractive="true"
                        fallback-placement="counterclockwise">
                      </b-tooltip>
                      <b-form-file
                        id="file"
                        v-model="elementData.file"
                        accept=".pdf"
                        placeholder="Seleccione el archivo o arrastrelo aquí.."
                        drop-placeholder="Suéltelo aquí..."
                        browse-text="Buscar" />
                      {{
                        elementData.file
                          ? elementData.file.name
                          : 'Archivo no seleccionado'
                      }}
                      <small class="text-danger">{{ errors[0] }}</small>
                    </b-col>
                    <b-col
                      v-if="elementData.report"
                      cols="3">
                      <a
                        id="view-report-button"
                        class="btn mb-1 mb-sm-0 mr-0 mr-sm-1 btn-secondary"
                        :href="getUrl(elementData.report)"
                        target="_blank">
                        {{ $t(elementName + '.actions.view_report') }}
                      </a>
                    </b-col>
                  </b-row>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col
              v-if="medicalCase && elementData.type.key == 'PATIENT'"
              cols="4">
              <b-form-group label-for="reviewedExams">
                <span class="font-weight-bold">{{
                  $t(elementName + '.object.reviewedExams')
                }}</span>
                <v-select
                  v-model="elementData.patient_exams_ok"
                  :options="madeByAvenirOptions"
                  label="text"
                  value="value"
                  :reduce="(option) => option.value"
                  :default="false"
                  disabled />
              </b-form-group>
            </b-col>
            <b-col cols="4">
              <b-form-group label-for="completed">
                <span class="font-weight-bold">{{
                  $t(elementName + '.object.completed')
                }}</span
                ><span class="text-danger">*</span>
                <validation-provider
                  #default="{ errors }"
                  name="completed"
                  rules="required">
                  <v-select
                    v-model="elementData.completed"
                    :options="madeByAvenirOptions"
                    label="text"
                    value="value"
                    :reduce="(option) => option.value"
                    :default="false" />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col cols="4">
              <b-form-group label-for="charge_checkbox">
                <span class="font-weight-bold">{{ $t(elementName + '.object.charge') }}</span>
                <b-form-checkbox
                  id="charge_checkbox"
                  v-model="elementData.charge">
                </b-form-checkbox>
              </b-form-group>
            </b-col>
          </b-row>

          <b-row>
            <b-col cols="12">
              <b-form-group label-for="notes">
                <span class="font-weight-bold">{{
                  $t(elementName + '.object.notes')
                }}</span>
                <b-form-textarea
                  id="notes"
                  v-model="elementData.notes"
                  maxlength="512" />
              </b-form-group>
            </b-col>
          </b-row>

          <b-row class="d-flex justify-content-end">
            <b-button
              class="mb-1 mb-sm-0 mr-0 mr-sm-1"
              @click="router().go(-1)">
              {{ $t('actions.back') }}
            </b-button>

            <b-button
              variant="primary"
              type="submit"
              class="mb-1 mb-sm-0 mr-0 mr-sm-1"
              :disabled="invalid">
              {{ $t('actions.save') }}
            </b-button>
          </b-row>
        </b-form>
      </validation-observer>
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard,
  BCardHeader,
  BCardBody,
  BButton,
  BRow,
  BCol,
  BFormInput,
  BFormGroup,
  BForm,
  BFormTextarea,
  BTooltip,
  BFormFile,
  BFormCheckbox,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import router from '@/router'
import DatePicker from '@/views/core/DatePicker.vue'
import vSelect from 'vue-select'
import '@core/scss/vue/libs/vue-select.scss'

export default {
  components: {
    BCard,
    BButton,
    BRow,
    BCol,
    BFormInput,
    BCardHeader,
    BCardBody,
    BFormGroup,
    BForm,
    ValidationProvider,
    ValidationObserver,
    BFormTextarea,
    BTooltip,
    BFormFile,
    vSelect,
    DatePicker,
    BFormCheckbox,
  },
  props: {
    elementData: {
      type: Object,
      required: true,
    },
    caseDate: {
      type: Object,
      required: false,
    },
    medicalCase: {
      type: Object,
      required: false,
    },
    elementName: {
      type: String,
      required: true,
    },
    caseId: {
      type: Number,
      required: true,
    },
    labs: {
      type: Array,
      required: true,
    },
    medicalDateTypes: {
      type: Array,
      required: true,
    },
    testResults: {
      type: Array,
      required: true,
    },
    unitsOfMeasure: {
      type: Array,
      required: true,
    },
    clinicalLaboratoryServicesList: {
      type: Array,
      required: true,
    },
    followTypeList: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      madeByAvenirOptions: [
        {
          text: this.$t('boolean.yes'),
          value: true,
        },
        {
          text: this.$t('boolean.no'),
          value: false,
        },
      ],
      selectedLab: '',
    }
  },
  methods: {
    onCUPSChange() {
      const cups = this.clinicalLaboratoryServicesList.filter(
        (cups) => cups.cups == this.elementData.cups
      )
      if (cups.length > 0) {
        this.elementData.name = cups[0].text
      }
    },
    updateLabs() {
      if (this.elementData) {
        const companyType = this.elementData.made_by_avenir ? 'SUPPLIER' : 'ENTERPRISE'
        this.$parent.fetchLabs(companyType)
      }
    },
    router() {
      return router
    },
    save() {
      this.$refs.elementForm.validate().then((success) => {
        if (success) {
          this.$parent.save(this.elementData)
        }
      })
    },
    getUrl(item) {
      if (!item || !item.path) {
        return ''
      }
      let path = item.path.split(/[\/,\\]+/)
      path = path[path.length - 1]
      return `${process.env.VUE_APP_BASE_API}/api/v1/digital_file/digital_file/record/${path}`
    },
    searchLab(search, loading) {
      if (search.length) {
        loading(true)
        const companyType = this.elementData.made_by_avenir
          ? 'SUPPLIER'
          : 'ENTERPRISE'
        this.$parent.fetchLabs(companyType, search, loading)
      }
    },
  },
}
</script>

<style></style>
