<template>
  <div>
    <!-- Alert: No item found -->
    <b-alert
      variant="danger"
      :show="elementData === undefined">
      <h4 class="alert-heading">
        {{ $t(RESOURCES_ELEMENT_NAME + '.search.error.fetching_data') }}
      </h4>
    </b-alert>
    <template v-if="elementData">
      <b-row>
        <b-col
          cols="12"
          xl="12"
          lg="12"
          md="12">
          <element-edit-card
            v-if="elementData"
            :element-data="elementData"
            :case-date="caseDate"
            :element-name="RESOURCES_ELEMENT_NAME"
            :case-id="caseId"
            :type="type"
            :labs="labs"
            :medical-date-types="medicalDateTypes"
            :test-results="testResults"
            :units-of-measure="unitsOfMeasure"
            :medical-case="medicalCase"
            :charge="charge"
            :clinical-laboratory-services-list="clinicalLaboratoryServicesList"
            :follow-type-list="followTypeList" />
        </b-col>
      </b-row>
    </template>
  </div>
</template>

<script>
import { BRow, BCol, BButton, BAlert, BLink } from 'bootstrap-vue'
import store from '@/store'
import ElementStoreModule from '@/views/case/labtests/storeModule'
import router from '@/router'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { PrinterIcon } from 'vue-feather-icons'
import ElementEditCard from './EditCard.vue'

export default {
  components: {
    BRow,
    BCol,
    BButton,
    BAlert,
    BLink,
    BAlert,
    ElementEditCard,
  },
  data() {
    return {
      elementData: undefined,
      ELEMENT_APP_STORE_MODULE_NAME: '',
      RESOURCES_ELEMENT_NAME: '',
      caseId: undefined,
      labs: [],
      medicalDateTypes: [],
      testResults: [],
      unitsOfMeasure: [],
      newElement: false,
      charge: false,
      type: undefined,
      medicalCase: {
        source_exams_ok: false,
        patient_exams_ok: undefined,
      },
      caseDate: {
        type: {
          value: '',
          key: '',
        },
      },
      clinicalLaboratoryServicesList: [],
      followTypeList: [],
    }
  },
  async mounted() {
    this.RESOURCES_ELEMENT_NAME = 'labtests'
    this.ELEMENT_APP_STORE_MODULE_NAME = `app-mh-v-${this.RESOURCES_ELEMENT_NAME}`

    this.caseId = parseInt(router.currentRoute.params.case_id)
    this.type = router.currentRoute.params.type

    // Register module
    if (!store.hasModule(this.ELEMENT_APP_STORE_MODULE_NAME))
      store.registerModule(
        this.ELEMENT_APP_STORE_MODULE_NAME,
        ElementStoreModule
      )

    if (router.currentRoute.params.id) {
      await this.fetchElement()
      this.newElement = false
      await this.fetchCaseDate()
    } else {
      this.elementData = {
        provider_id: null,
        report_id: null,
        type: {
          key: this.type,
        },
        case_id: this.caseId,
        result_id: null,
        unit_id: null,
        made_by_avenir: false,
        completed: false,
        date: null,
      }
      this.newElement = true
      const companyType = this.elementData.made_by_avenir
        ? 'SUPPLIER'
        : 'ENTERPRISE'
      this.fetchLabs(companyType)
    }
    await this.fetchMedicalDateTypes()
    await this.fetchTestResults()
    await this.fetchUnitsOfMeasure()
    await this.fetchClinicalLaboratoryServices()
    await this.fetchFollowTypes()
    await this.fetchCase()
  },
  unmounted() {
    // UnRegister on leave
    if (store.hasModule(this.ELEMENT_APP_STORE_MODULE_NAME))
      store.unregisterModule(this.ELEMENT_APP_STORE_MODULE_NAME)
  },
  methods: {
    fetchCaseDate() {
      store
        .dispatch(`${this.ELEMENT_APP_STORE_MODULE_NAME}/fetchCaseDate`, {
          id: router.currentRoute.params.id,
        })
        .then((response) => {
          this.caseDate = response.data
          console.info('caseDate:', this.caseDate)
        })
        .catch((error) => {
          if (error.response.status === 404) {
            console.info('404', this.elementData)
          }
        })
    },
    fetchCase() {
      store
        .dispatch(`${this.ELEMENT_APP_STORE_MODULE_NAME}/fetchCase`, {
          id: router.currentRoute.params.case_id,
        })
        .then((response) => {
          this.medicalCase = response.data
          this.elementData.patient_exams_ok =
            response.data.patient_exams_ok !== undefined
              ? response.data.patient_exams_ok
              : this.medicalCase.patient_exams_ok
        })
        .catch((error) => {
          if (error.response.status === 404) {
            console.info('404', this.elementData)
          }
        })
    },
    async fetchElement() {
      store
        .dispatch(`${this.ELEMENT_APP_STORE_MODULE_NAME}/fetch_element`, {
          id: router.currentRoute.params.id,
        })
        .then((response) => {
          this.elementData = response.data
          if (!this.elementData.completed) {
            this.elementData.completed = false
          }
          const companyType = this.elementData.made_by_avenir
            ? 'SUPPLIER'
            : 'ENTERPRISE'
          this.fetchLabs(companyType)
        })
        .catch((error) => {
          if (error.response.status === 404) {
            this.elementData = undefined
          }
        })
    },
    fetchReport() {
      store
        .dispatch(`${this.ELEMENT_APP_STORE_MODULE_NAME}/fetchDocuments`, {
          prst_uuid: this.prst_uuid,
        })
        .then((response) => {
          this.elementData.report = response.data
        })
        .catch((error) => {
          if (error.response.status === 404) {
            console.info('404', this.elementData)
            this.elementData = undefined
          }
        })
    },
    fetchLabs(companyType, search, loading) {
      const currentLabName = this.elementData.provider_id && search === undefined
        ? (this.labs.find(lab => lab.id === this.elementData.provider_id)?.name 
        || this.elementData.provider.name)
        : null;
      const Search = search !== undefined ? search : currentLabName
      store.dispatch(`${this.ELEMENT_APP_STORE_MODULE_NAME}/fetchLabs`, {
        companyType,
        query: Search,
      })
        .then(response => {
          this.labs = response.data.items
          const foundLab = this.labs.find(lab => lab.name === Search)
          this.elementData.provider_id = foundLab ? foundLab.id : null
          this.elementData.provider = foundLab ? foundLab : null
          if (loading) {
            loading(false)
          }
        })
        .catch((error) => {
          if (error.response.status === 404) {
            console.info('404', this.elementData)
            this.elementData = undefined
          }
        })
    },
    fetchTestResults() {
      store
        .dispatch(`${this.ELEMENT_APP_STORE_MODULE_NAME}/fetchTestResults`, {})
        .then((response) => {
          this.testResults = response.data.items
        })
        .catch((error) => {
          if (error.response.status === 404) {
            console.info('404', this.elementData)
            this.elementData = undefined
          }
        })
    },
    fetchUnitsOfMeasure() {
      store
        .dispatch(
          `${this.ELEMENT_APP_STORE_MODULE_NAME}/fetchUnitsOfMeasure`,
          {}
        )
        .then((response) => {
          this.unitsOfMeasure = response.data.items
        })
        .catch((error) => {
          if (error.response.status === 404) {
            console.info('404', this.elementData)
            this.elementData = undefined
          }
        })
    },
    fetchMedicalDateTypes() {
      store
        .dispatch(
          `${this.ELEMENT_APP_STORE_MODULE_NAME}/fetchMedicalDateTypes`,
          {}
        )
        .then((response) => {
          this.medicalDateTypes = response.data.items
        })
        .catch((error) => {
          if (error.response.status === 404) {
            console.info('404', this.elementData)
            this.elementData = undefined
          }
        })
    },
    async fetchClinicalLaboratoryServices() {
      return store
        .dispatch(`${this.ELEMENT_APP_STORE_MODULE_NAME}/fetch_services`, {
          queryParams: {
            perPage: 10000,
            page: 1,
            query: '',
          },
        })
        .then((response) => {
          const ans = response.data.items.map((ser) => ({
            service_type: ser.service_type,
            cups: ser.cups,
            text: `${ser.cups} ${ser.description}`,
          }))

          this.clinicalLaboratoryServicesList = ans.filter(
            (element) => element.service_type.key === 'LABORATORIES'
          )
        })
        .catch(() => [])
    },
    async addFile(fileData) {
      const case_id = this.caseId
      const type = this.elementData.type.key
      return store.dispatch(`${this.ELEMENT_APP_STORE_MODULE_NAME}/addFile`, { fileData, case_id, type })
        .then(response => response.data).catch(error => {
          console.info(error)
          return undefined
        })
    },
    async fetchFollowTypes() {
      store
        .dispatch(`${this.ELEMENT_APP_STORE_MODULE_NAME}/fetch_key_value`, {
          type: 'case_tracking_type',
        })
        .then((response) => {
          // #'destination_order', 'case_tracking_type'
          this.followTypeList = response.data.items
        })
        .catch((error) => {
          if (error.response.status === 404) {
            console.info(error)
            return undefined
          }
        })
    },
    async save(elementData) {
      const method =
        this.ELEMENT_APP_STORE_MODULE_NAME +
        (this.newElement ? '/add_element' : '/save_element')
      const action = this.newElement ? 'creation' : 'update'
      const _self = this

      return store
        .dispatch(method, elementData)
        .then((response) => {
          _self.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: _self.$t(
                `${_self.RESOURCES_ELEMENT_NAME}.action_results.${action}.title`
              ),
              icon: 'SaveIcon',
              variant: 'success',
              text: _self.$t(
                `${_self.RESOURCES_ELEMENT_NAME}.action_results.${action}.success`
              ),
            },
          })
          _self.$router.go(-1)
          return true
        })
        .catch((error) => {
          console.info(error)
          _self.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: _self.$t(
                `${_self.RESOURCES_ELEMENT_NAME}.action_results.${action}.title`
              ),
              icon: 'SaveIcon',
              variant: 'danger',
              text: _self.$t(
                `${_self.RESOURCES_ELEMENT_NAME}.action_results.${action}.failure`
              ),
            },
          })
          return false
        })
    },
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
